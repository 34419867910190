import React, { useCallback } from "react"
import PropTypes from "prop-types"
import Div from "@components/elements/div"
import styled from "styled-components"
import { motion } from "framer-motion"
import theme from "../../styles/theme"
import useDebouncedState from "@hooks/use-debounced-state"

const StyledCard = styled(Div)`
  border-radius: 1rem;

  @property --rotationDegrees {
    syntax: "<angle>";
    inherits: false;
    initial-value: 0deg;
  }

  background: linear-gradient(
    to bottom,
    color-mix(in srgb, ${theme.color.indigo200} 25%, transparent),
    color-mix(in srgb, ${theme.color.indigo100} 25%, transparent)
  );
  border: 1px solid transparent;
  border-image-slice: 1;

  transition: filter 0.4s ease;

  filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0));
  background-clip: padding-box;
  padding: 2px;
  position: relative;
  backdrop-filter: blur(40px);
`

const StyledBorder = styled.div`
  content: "";
  top: 0;
  right: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  inset: 0;
  border-radius: 1rem;
  padding: 1px;
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
  pointer-events: none;
`

const PseudoBorder = motion(StyledBorder)

const glowAnim = {
  initial: {
    "--rotationDegrees": "180deg",
    background:
      "conic-gradient(from var(--rotationDegrees), #B0B8FC, #B0B8FC, #B0B8FC, #B0B8FC)",
  },
  active: {
    "--rotationDegrees": "540deg",
    background:
      "conic-gradient(from var(--rotationDegrees), #aeddfa, #bd90e5, #f09591, #aeddfa)",
    transition: {
      borderImage: {
        duration: 0.4,
        ease: "easeIn",
      },
      "--rotationDegrees": {
        duration: 8,
        ease: "linear",
        repeat: Infinity,
      },
    },
  },
}

export const GlowBorderCard = ({ handleClick, children }) => {
  const [isHovering, setIsHovering] = useDebouncedState(false, 200)

  const handleMouseOver = useCallback(() => {
    setIsHovering(true)
  }, [setIsHovering])

  const handleMouseOut = useCallback(() => {
    setIsHovering(false)
  }, [setIsHovering])

  return (
    <StyledCard
      onClick={handleClick}
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseOut}
    >
      <PseudoBorder
        variants={glowAnim}
        initial={"initial"}
        animate={isHovering ? "active" : "initial"}
        style={{ "--rotationDegrees": "180deg" }}
      />
      {children}
    </StyledCard>
  )
}

GlowBorderCard.propTypes = {
  handleClick: PropTypes.func,
  children: PropTypes.node,
}
