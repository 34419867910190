import React from "react"
import Layout from "@components/layout"
import PropTypes from "prop-types"
import Heading from "@elements/heading"
import Paragraph from "@elements/paragraph"
import Flex from "@components/elements/flex"
import Anchor from "@elements/anchor"
import Button from "@components/elements/button"
import Div from "@components/elements/div"
import Grid from "@components/elements/grid"
import { graphql } from "gatsby"
import Html from "@components/html"
import { HTMLWrapper } from "@components/common/html-wrapper"
import { HighGradient, LowGradient } from "@components/common/gradient"
import { useGradient } from "@hooks/use-gradient"
import { useTheme } from "styled-components"
import { GlowBorderCard } from "@components/card/glow-border"
import { PowerMetricsEOPCta } from "@components/cta/eop/powermetrics"
import Image from "@components/image"
import { PmFeatureGallery } from "@components/product/powermetrics/feature-gallery"
import { LogoCarousel } from "@components/brand/logo-carousel"
import { TestimonialUser } from "@components/common/testimonial"

const Warehouse = ({ data, pageContext }) => {
  const warehouse = data.warehouses
  const { gradient } = useGradient()
  const { color, shadow } = useTheme()

  const grid = [
    {
      title: warehouse.grid_1_title,
      subtitle: warehouse.grid_1_subtitle,
      image: warehouse.grid_1_image,
      description: warehouse.grid_1_description,
    },
    {
      title: warehouse.grid_2_title,
      subtitle: warehouse.grid_2_subtitle,
      image: warehouse.grid_2_image,
      description: warehouse.grid_2_description,
    },
    {
      title: warehouse.grid_3_title,
      subtitle: warehouse.grid_3_subtitle,
      image: warehouse.grid_3_image,
      description: warehouse.grid_3_description,
    },
  ]

  return (
    <Layout
      seo={warehouse.seo}
      fullWidth
      marginless
      canonical={pageContext.slug}
    >
      <Div position="relative" overflow="hidden">
        <Div
          padding="8rem 0 0rem"
          position="relative"
          background={color.indigo700}
        >
          <HighGradient src={gradient.cdn} loading="eager" />
          <Div position="relative" margin="0 auto">
            <Heading
              margin="0 auto"
              maxWidth={"30ch"}
              color="white"
              center
              fontSize="2.8rem"
            >
              {warehouse.heading}
            </Heading>
            <Image
              file={warehouse.logo}
              style={{ margin: "0 auto", maxWidth: "990px" }}
            />
            <Flex textContainer gap="1rem">
              <Paragraph
                center
                color="white"
                lineHeight={1.7}
                margin="2rem auto 1rem"
              >
                {warehouse.description}
              </Paragraph>
              <Flex
                flexFlow="row"
                flexFlowSm="column"
                margin="0 auto"
                gap="1rem"
              >
                <Button.PowerMetrics notice={false}>
                  Get started free
                </Button.PowerMetrics>
                <Button
                  disableBrightness
                  background="transparent"
                  hoverBackground={"white"}
                  hoverColor={color.indigo600}
                  style={{
                    margin: "-2px",
                    boxSizing: "border-box",
                    border: `2px solid white`,
                  }}
                  color={"white"}
                  notice={false}
                  href={warehouse.docs_link}
                >
                  Read the docs
                </Button>
              </Flex>
            </Flex>
            <Paragraph
              color="white"
              margin="4rem auto 1rem"
              center
              fontWeight={600}
              lineHeight={1.6}
            >
              You’ll be in great company with over 1,600 other happy customers
            </Paragraph>
            <LogoCarousel />
            {warehouse.testimonial && (
              <Flex
                gap="1.5rem"
                padding="2.5rem"
                paddingSm="1.5rem"
                borderRadius="1rem"
                justifyContent="center"
                textContainer
              >
                <Paragraph color="white" lineHeight={1.6} center>
                  {warehouse.testimonial}
                </Paragraph>
                <TestimonialUser
                  color="white"
                  name={warehouse.testimonial_person.name}
                  title={warehouse.testimonial_person.title}
                  image={warehouse.testimonial_person.headshot}
                />
              </Flex>
            )}
          </Div>
        </Div>
        <Div
          padding="0 0 6rem"
          background={color.indigo700}
          position="relative"
          overflow="hidden"
          margin="0 auto 6rem"
        >
          <LowGradient src={gradient.cdn} />
          <Div center margin="0 auto 4rem" textContainer position="relative">
            <Heading color="white" as="h2" margin="6rem 0 2rem" center>
              How it works
            </Heading>
            <Html
              styles={{
                paragraph: { color: "white" },
                heading: { color: "white" },
                link: { color: "white" },
                list: { color: "white" },
              }}
              parseElements
              html={warehouse.how_it_works}
              images={warehouse.how_it_works_images}
            />
          </Div>
          {/*
            <Div position="relative" textContainer margin="0 auto 3rem" center>
              <Heading color="white" center as="h2" margin="0 0 1rem">
                Provide the data
                <br />
                don’t build the dashboards
              </Heading>
              <Html
                styles={{
                  paragraph: { color: "white"},
                  heading: { color: "white"},
                  link: { color: "white"},
                  list: { color: "white"},
                }}
                parseElements
                html={warehouse.provide_data}
                images={warehouse.provide_data_images}
              />
            </Div>
          */}

          <PmFeatureGallery override={grid} />
        </Div>
        <Div container>
          <Heading as="h2" center maxWidth="30ch" margin="0 auto 2rem">
            PowerMetrics ♥ {warehouse.warehouse}
          </Heading>
          <Grid
            position="relative"
            container
            columns="repeat(3, 1fr)"
            columnsMd="1fr"
            gap="1rem"
            margin="0 auto 6rem"
            marginMd="0 auto 4rem"
          >
            {warehouse.features.map((feature, index) => (
              <GlowBorderCard key={index}>
                <Div padding="2rem">
                  <Flex
                    width="60px"
                    height="60px"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="100%"
                    background="white"
                    boxShadow={shadow.default}
                    margin="0 0 1.5rem"
                  >
                    <Image
                      width={40}
                      objectFit="scale-down"
                      file={feature.icon}
                    />
                  </Flex>
                  <Heading as="h4" margin="0 0 1rem">
                    {feature.name}
                  </Heading>
                  <Paragraph fontSize="1.2rem" lineHeight={1.6}>
                    {feature.description}
                  </Paragraph>
                </Div>
              </GlowBorderCard>
            ))}
          </Grid>
          <Div textContainer>
            <HTMLWrapper html={warehouse.integration_details} />
          </Div>
        </Div>
        <Div margin="0 auto 6rem" container>
          <Heading as="h3" center maxWidth="30ch" margin="0 auto 2rem">
            Learn more
          </Heading>
          <Flex gap="1rem">
            <Grid columns="repeat(3, 1fr)" columnsMd="1fr" gap="1rem">
              <GlowBorderCard>
                <Flex gap="1rem" padding="2rem">
                  <Heading as="h4">Blog</Heading>
                  <Anchor
                    lineHeight={1.7}
                    link="https://www.klipfolio.com/blog/data-warehouse-cube-integrations"
                  >
                    New data warehouse integrations and Cube semantic layer
                    support
                  </Anchor>
                </Flex>
              </GlowBorderCard>
              <GlowBorderCard>
                <Flex gap="1rem" padding="2rem">
                  <Heading as="h4">Webinar</Heading>
                  <Anchor
                    link={"/resources/webinars/metrics-ready"}
                    lineHeight={1.7}
                  >
                    Get Metrics-Ready with your Existing Data Stack
                  </Anchor>
                </Flex>
              </GlowBorderCard>
              <GlowBorderCard>
                <Flex gap="1rem" padding="2rem">
                  <Heading as="h4">Documentation</Heading>
                  <Anchor
                    lineHeight={1.7}
                    link="https://support.klipfolio.com/hc/en-us/articles/23966450416407-PowerMetrics-Connecting-to-Databricks-and-creating-metrics"
                  >
                    {warehouse.docs_text}
                  </Anchor>
                </Flex>
              </GlowBorderCard>
            </Grid>
          </Flex>
        </Div>
        <PowerMetricsEOPCta
          heading={"Make analytics everybody's business"}
          description={"Get started with a free account."}
        />
      </Div>
    </Layout>
  )
}

Warehouse.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

/* TODO if we add testimonial add to query
  testimonial
  testimonial_person {
    id
    name
    title
    company
    headshot {
      cdn
      placeholder
      id
      directus_id
    }
  }
*/

export const pageQuery = graphql`
  query WarehouseQuery($id: String!) {
    warehouses(id: { eq: $id }) {
      seo {
        ...seoData
      }
      logo {
        ...imageData
      }
      warehouse
      heading
      description
      how_it_works
      how_it_works_images {
        ...imageData
      }
      provide_data
      provide_data_images {
        ...imageData
      }
      integration_details
      integration_details_images {
        ...imageData
      }
      features {
        icon {
          ...imageData
        }
        name
        description
      }
      testimonial
      testimonial_person {
        name
        title
        headshot {
          ...imageData
        }
      }
      related_blog {
        title
        slug
      }
      related_webinar {
        seo {
          title
        }
        slug
      }
      docs_text
      docs_link
      grid_1_title
      grid_1_subtitle
      grid_1_image {
        ...imageData
      }
      grid_1_description
      grid_2_title
      grid_2_subtitle
      grid_2_image {
        ...imageData
      }
      grid_2_description
      grid_3_title
      grid_3_subtitle
      grid_3_image {
        ...imageData
      }
      grid_3_description
    }
  }
`

export default Warehouse
