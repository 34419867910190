import React from "react"
import Div from "@components/elements/div"
import { graphql, useStaticQuery } from "gatsby"
import styled, { keyframes } from "styled-components"
import Image from "@components/image"

const scrollingAnim = keyframes`
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(-1447px, 0, 0);
  }
`

export const Animation = styled.div`
  width: 2894px;
  display: flex;
  gap: 0;
  animation: ${scrollingAnim} 60s linear infinite;

  .klipfolio-image-wrapper {
    width: 1447px;
    height: 86px;
  }
`

export const LogoCarousel = () => {
  const { metrics } = useStaticQuery(
    graphql`
      query BrandLogoCarousel {
        metrics: directusFiles(
          directus_id: { eq: "4fc3d603-d6d4-40f1-89bc-d62cbe727a41" }
        ) {
          cdn(width: 2893)
          directus_id
          id
          placeholder
          title
        }
      }
    `
  )

  return (
    <Div
      maxWidth="600px"
      width="100%"
      overflow="hidden"
      style={{
        WebkitMaskImage:
          "linear-gradient(90deg, transparent 0%, #FFF 30%, #FFF 70%, transparent 100%)",
      }}
    >
      <Animation>
        <Image noDimensions file={metrics} />
        <Image noDimensions file={metrics} />
      </Animation>
    </Div>
  )
}
