import React from "react"
import Flex from "@components/elements/flex"
import Paragraph from "@components/elements/paragraph"
import Image from "@components/image"
import PropTypes from "prop-types"
import Grid from "@components/elements/grid"
import Div from "@components/elements/div"

export const TestimonialUser = ({
  color,
  name,
  title,
  company,
  image,
  multiline,
}) => {
  return multiline ? (
    <Grid
      columns="repeat(2, max-content)"
      columnsSm="1fr"
      gap="1rem"
      gapSm="0.5rem"
      flexFlow="row"
      textAlign="left"
      alignItems="center"
      height="min-content"
    >
      <Image
        aspectRatio={"1/1"}
        file={image}
        style={{
          aspectRatio: "1/1",
          maxHeight: multiline ? "60px" : "50px",
          maxWidth: multiline ? "60px" : "50px",
        }}
        radius={4}
      />
      <Flex flexFlow={multiline ? "column" : "row"}>
        <Paragraph
          color={color}
          fontSize="1.1rem"
          margin={company ? "0" : "0 0 -0.3rem"}
        >
          <b>{multiline ? name : `${name},`}</b>
        </Paragraph>
        {company ? (
          <>
            <Paragraph color={color} fontSize="1rem" lineHeight="1.3">
              {title},
            </Paragraph>
            <Paragraph color={color} fontSize="1rem" lineHeight="1.3">
              {company}
            </Paragraph>
          </>
        ) : (
          <Paragraph color={color} fontSize="1.1rem">
            {title}
          </Paragraph>
        )}
      </Flex>
    </Grid>
  ) : (
    <Grid
      columns="repeat(2, max-content)"
      columnsSm="1fr"
      gap="1rem"
      gapSm="0.5rem"
      flexFlow="row"
      margin="0 auto"
      justifyItems="center"
    >
      <Image
        aspectRatio={"1/1"}
        file={image}
        style={{ aspectRatio: "1/1", maxHeight: "35px", maxWidth: "35px" }}
        radius={4}
      />
      <Grid
        columns="repeat(2, max-content)"
        gap="0.3rem"
        gapSm="0"
        columnsSm={"1fr"}
      >
        <Paragraph color={color} fontSize="1.1rem" margin="0 0 -0.3rem" center>
          <b>{name}</b>,
        </Paragraph>
        <Paragraph color={color} fontSize="1.1rem" center>
          {title}
        </Paragraph>
      </Grid>
    </Grid>
  )
}

TestimonialUser.propTypes = {
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  company: PropTypes.string,
  image: PropTypes.object.isRequired,
  multiline: PropTypes.bool,
}

export const TestimonialUserVertical = ({
  color,
  name,
  title,
  company,
  image,
}) => {
  return (
    <Div margin="0 auto" center>
      <Image
        aspectRatio={"1/1"}
        file={image}
        style={{
          margin: "0 auto 1rem",
          aspectRatio: "1/1",
          maxHeight: "50px",
          maxWidth: "50px",
        }}
        radius={4}
      />
      <Paragraph color={color} fontSize="1rem" lineHeight="150%" center>
        <b>{name}</b>
      </Paragraph>
      <Paragraph color={color} fontSize="1rem" lineHeight="150%" center>
        {title}
      </Paragraph>
      <Paragraph color={color} fontSize="1rem" lineHeight="150%" center>
        {company}
      </Paragraph>
    </Div>
  )
}

TestimonialUserVertical.propTypes = {
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
}

export const Testimonial = ({
  color,
  margin,
  testimonial,
  name,
  title,
  image,
}) => (
  <Flex
    margin={margin ?? "2rem auto 6rem"}
    gap="1.5rem"
    maxWidth="800px"
    center
  >
    <Paragraph color={color} lineHeight="150%">
      {testimonial}
    </Paragraph>
    <TestimonialUser color={color} name={name} title={title} image={image} />
  </Flex>
)

Testimonial.propTypes = {
  color: PropTypes.string,
  margin: PropTypes.string,
  testimonial: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
}
